






















































import {Component, Mixins} from "vue-property-decorator";
import XForm from "@/components/SimpleForm.vue";
import { paymentForm } from "@/assets/scripts/form-fields/main";
import {validationMixin} from "vuelidate";
import AppApiMixin from "@/components/mixins/AppApi.vue";
import {mapGetters, mapState} from "vuex";

const { values, attrs, validations, errors } = paymentForm;

@Component({
  components: {
    XForm,
  },
  computed: {
    ...mapState({ appEnviroment: "appEnviroment" }),
    ...mapGetters({
      contractId: "contract/id",
    }),
  },
  mixins: [validationMixin],
  validations,
})
class PaymentCard extends Mixins(AppApiMixin, XForm) {
  [x: string]: any

  values = values
  defaultValues: {[x: string]: any} = {}
  allFieldAttrs = attrs;

  useSubmitButtonLoading = false;
  submitSuccess = '';
  submitError = "";

  mounted() {
    this.values = {...this.values, ...this.$attrs};
    this.defaultValues = {...this.values, ...this.$attrs};
  }

  async submitForm() {
    this.useSuccessAlert = false
    this.useSubmitButtonLoading = true

    const formData = new FormData
    for (let key in this.values) {
      formData.append(key, this.values[key]);
    }

    formData.append('contractId', this.contractId);
    // formData.append('author', this.temp_mas[0]);

    try {
      const data = await this.getPaymentURL(formData)

      if(data && data.result && (data.url) != 0) {
        window.location.href = data.url;
      }
    } catch (e) {
      console.log('error', e);
      this.submitError = e.data;
    }
    this.submitSuccess = '';
    this.useSubmitButtonLoading = false
  }

  changeAmount() {
    const { appEnviroment } = this;
    this.values['total'] = this.values['amount'] + (this.values['amount'] * (+appEnviroment.constants["PaymentFee"] || 0));
  }
}

export default PaymentCard;
